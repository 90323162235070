import {
  logoDT,
  logoCE,
  logoSelo,
  logoAT,
  logoGOV,
  logoTerritorio,
} from "./logosSistemas";
import titulos from "./titleSistema";

/**
 * @description
 * O default serve para colocar os valores que mais aparecem, para que só precise mudar nos diferentes e diminuir o trabalho braçal.
 * Tome cuidado pois elas serão setadas para todas as opções, você pode mudar manualmente em cada uma para setar diferentes.
 */
const ufsDefaultDefinitions = {
  na: true,
  logo: "logo_nacional.png",
  corPrimaria: "#000",
  isSala: false,
  isIndicadores: false,
  svg: process.env.VUE_APP_SVG_UF,
};

/**
 * @description
 * Valores referentes a cada tipo de municipio e suas respectivas definições, você deve colocar aqui somente nos caras que são
 * diferentes. Tente usar o ufsDefaultDefinitions para colocar as opções mais comuns entre eles.
 */
const ufsPorDefinitions = [
  // #region HOMOLOGAÇÃO
  {
    tag: "uf-hlg.",
    banco: "ba",
    ufEmail: "uf-hlg",
    env: process.env.VUE_APP_BASE_API_UF_HLG,
    uf: "BA",
    logo: "sebrae-ba-logo.png",
    svg: process.env.VUE_APP_SVG_BA,
  },
  {
    tag: "na-hlg.",
    banco: "ce-na-hlg",
    ufEmail: "na-hlg",
    env: process.env.VUE_APP_BASE_API_NA_HLG,
    na: true,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
  },

  {
    tag: "rr-hlg",
    banco: "ce-rr-hlg",
    ufEmail: "rr-hlg",
    env: process.env.VUE_APP_BASE_API_UF_HLG,
    uf: "RR",
    logo: "sebrae-ba-logo.png",
    svg: process.env.VUE_APP_SVG_RR,
  },
  {
    tag: "uf-hlg.sistemaselo",
    isSala: true,
    banco: "selo-sala-ba",
    ufEmail: "uf-hlg",
    env: process.env.VUE_APP_BASE_API_SELO_UF_HLG,
    uf: "BA",
    logo: "sebrae-ba-logo.png",
    svg: process.env.VUE_APP_SVG_BA,
  },
  {
    tag: "na-hlg.sistemaselo",
    isSala: true,
    banco: "selo-painel-na",
    ufEmail: "na-hlg",
    env: process.env.VUE_APP_BASE_API_SELO_NA_HLG,
    na: true,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
  },
  // #endregion HOMOLOGAÇÃO

  // #region PRODUÇÃO
  {
    tag: "painel.cidadeempreendedora",
    ufEmail: "na",
    banco: "ce-na-hlg",
    env: process.env.VUE_APP_BASE_API_NA,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
    na: true,
  },

  {
    tag: "ac.",
    banco: "ac",
    ufEmail: "ac",
    uf: "AC",
    svg: process.env.VUE_APP_SVG_AC,
  },
  {
    tag: "ba.",
    banco: "ba",
    ufEmail: "ba",
    logo: "sebrae-ba-logo.png",
    uf: "BA",
    svg: process.env.VUE_APP_SVG_BA,
  },
  {
    tag: "ap.",
    banco: "ap",
    ufEmail: "ap",
    uf: "AP",
    svg: process.env.VUE_APP_SVG_AP,
  },
  {
    tag: "am.",
    banco: "am",
    ufEmail: "am",
    uf: "AM",
    svg: process.env.VUE_APP_SVG_AM,
  },
  {
    tag: "ce.",
    banco: "ce",
    ufEmail: "ce",
    uf: "CE",
    svg: process.env.VUE_APP_SVG_CE,
  },
  {
    tag: "es.",
    banco: "es",
    ufEmail: "es",
    uf: "ES",
    svg: process.env.VUE_APP_SVG_ES,
  },
  {
    tag: "go.",
    banco: "go",
    ufEmail: "go",
    uf: "GO",
    svg: process.env.VUE_APP_SVG_GO,
  },
  {
    tag: "mt.",
    banco: "mt",
    ufEmail: "mt",
    uf: "MT",
    svg: process.env.VUE_APP_SVG_MT,
  },
  {
    tag: "pa.",
    banco: "pa",
    ufEmail: "pa",
    uf: "PA",
    svg: process.env.VUE_APP_SVG_PA,
  },
  {
    tag: "pb.",
    banco: "pb",
    ufEmail: "pb",
    uf: "PB",
    svg: process.env.VUE_APP_SVG_PB,
  },
  {
    tag: "pr.",
    banco: "pr",
    ufEmail: "pr",
    uf: "PR",
    svg: process.env.VUE_APP_SVG_PR,
  },
  {
    tag: "pe.",
    banco: "pe",
    ufEmail: "pe",
    uf: "PE",
    svg: process.env.VUE_APP_SVG_PE,
  },
  {
    tag: "pi.",
    banco: "pi",
    ufEmail: "pi",
    uf: "PI",
    svg: process.env.VUE_APP_SVG_PI,
  },
  {
    tag: "rn.",
    banco: "rn",
    ufEmail: "rn",
    uf: "RN",
    svg: process.env.VUE_APP_SVG_RN,
  },
  {
    tag: "mg.",
    banco: "mg",
    ufEmail: "mg",
    uf: "MG",
    svg: process.env.VUE_APP_SVG_MG,
  },
  {
    tag: "rs.",
    banco: "rs",
    ufEmail: "rs",
    uf: "RS",
    svg: process.env.VUE_APP_SVG_RS,
  },
  {
    tag: "ro.",
    banco: "ro",
    ufEmail: "ro",
    uf: "RO",
    svg: process.env.VUE_APP_SVG_RO,
  },
  {
    tag: "rr.",
    banco: "rr",
    ufEmail: "rr",
    uf: "RR",
    svg: process.env.VUE_APP_SVG_RR,
  },
  {
    tag: "se.",
    banco: "se",
    ufEmail: "se",
    uf: "SE",
    svg: process.env.VUE_APP_SVG_SE,
  },
  {
    tag: "to.",
    banco: "to",
    ufEmail: "to",
    uf: "TO",
    svg: process.env.VUE_APP_SVG_TO,
  },
  {
    tag: "df.",
    banco: "df",
    ufEmail: "df",
    uf: "DF",
    svg: process.env.VUE_APP_SVG_DF,
  },
  {
    tag: "rj",
    banco: "rj",
    ufEmail: "rj",
    uf: "RJ",
    svg: process.env.VUE_APP_SVG_RJ,
  },
  {
    tag: "sp.",
    banco: "cidade-empreendedora-sp-indicadores",
    ufEmail: "sp",
    logo: "logo.png",
    uf: "SP",
    svg: process.env.VUE_APP_SVG_SP,
  },
  {
    tag: "sp-indicadores",
    banco: "cidade-empreendedora-sp-indicadores",
    ufEmail: "sp",
    logo: "logo.png",
    uf: "SP",
    svg: process.env.VUE_APP_SVG_SP,
    isIndicadores: true,
  },
  {
    tag: "ms.",
    banco: "ms",
    ufEmail: "ms",
    uf: "MS",
    svg: process.env.VUE_APP_SVG_MS,
  },
  {
    tag: "al.",
    banco: "al",
    ufEmail: "al",
    uf: "AL",
    svg: process.env.VUE_APP_SVG_AL,
  },
  {
    tag: "ma.",
    banco: "ma",
    ufEmail: "ma",
    uf: "MA",
    svg: process.env.VUE_APP_SVG_MA,
  },
  {
    tag: "sc.",
    banco: "sc",
    ufEmail: "sc",
    uf: "SC",
    svg: process.env.VUE_APP_SVG_SC,
  },
  {
    tag: "sala.rj.",
    banco: "rj",
    ufEmail: "rj",
    uf: "RJ",
    isSala: true,
    isIndicadores: true,
  },
  // #endregion PRODUÇÃO

  //#region Selo
  {
    tag: "painel.sistemaselo",
    isSala: true,
    ufEmail: "na",
    banco: "selo-painel-na",
    env: process.env.VUE_APP_BASE_API_SELO_NA,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
    na: true,
  },
  {
    tag: "painel-parceiros.sistemaselo",
    isSala: true,
    ufEmail: "na",
    banco: "selo-painel-na-parceiros",
    env: process.env.VUE_APP_BASE_API_SELO_NA,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
    na: true,
  },
  {
    tag: "ac.sistemaselo",
    isSala: true,
    isSala: true,
    banco: "selo-sala-ac",
    ufEmail: "ac",
    uf: "AC",
    svg: process.env.VUE_APP_SVG_AC,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "ba.sistemaselo",
    isSala: true,
    banco: "selo-sala-ba",
    ufEmail: "ba",
    logo: "sebrae-ba-logo.png",
    uf: "BA",
    svg: process.env.VUE_APP_SVG_BA,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "ap.sistemaselo",
    isSala: true,
    banco: "selo-sala-ap",
    ufEmail: "ap",
    uf: "AP",
    svg: process.env.VUE_APP_SVG_AP,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "am.sistemaselo",
    isSala: true,
    banco: "selo-sala-am",
    ufEmail: "am",
    uf: "AM",
    svg: process.env.VUE_APP_SVG_AM,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "ce.sistemaselo",
    isSala: true,
    banco: "selo-sala-ce",
    ufEmail: "ce",
    uf: "CE",
    svg: process.env.VUE_APP_SVG_CE,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "es.sistemaselo",
    isSala: true,
    banco: "selo-sala-es",
    ufEmail: "es",
    uf: "ES",
    svg: process.env.VUE_APP_SVG_ES,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "go.sistemaselo",
    isSala: true,
    banco: "selo-sala-go",
    ufEmail: "go",
    uf: "GO",
    svg: process.env.VUE_APP_SVG_GO,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "mt.sistemaselo",
    isSala: true,
    banco: "selo-sala-mt",
    ufEmail: "mt",
    uf: "MT",
    svg: process.env.VUE_APP_SVG_MT,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "pa.sistemaselo",
    isSala: true,
    banco: "selo-sala-pa",
    ufEmail: "pa",
    uf: "PA",
    svg: process.env.VUE_APP_SVG_PA,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "pb.sistemaselo",
    isSala: true,
    banco: "selo-sala-pb",
    ufEmail: "pb",
    uf: "PB",
    svg: process.env.VUE_APP_SVG_PB,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "pr.sistemaselo",
    isSala: true,
    banco: "selo-sala-pr",
    ufEmail: "pr",
    uf: "PR",
    svg: process.env.VUE_APP_SVG_PR,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "pe.sistemaselo",
    isSala: true,
    banco: "selo-sala-pe",
    ufEmail: "pe",
    uf: "PE",
    svg: process.env.VUE_APP_SVG_PE,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "pi.sistemaselo",
    isSala: true,
    banco: "selo-sala-pi",
    ufEmail: "pi",
    uf: "PI",
    svg: process.env.VUE_APP_SVG_PI,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "rn.sistemaselo",
    isSala: true,
    banco: "selo-sala-rn",
    ufEmail: "rn",
    uf: "RN",
    svg: process.env.VUE_APP_SVG_RN,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "mg.sistemaselo",
    isSala: true,
    banco: "selo-sala-mg",
    ufEmail: "mg",
    uf: "MG",
    svg: process.env.VUE_APP_SVG_MG,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "rs.sistemaselo",
    isSala: true,
    banco: "selo-sala-rs",
    ufEmail: "rs",
    uf: "RS",
    svg: process.env.VUE_APP_SVG_RS,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "ro.sistemaselo",
    isSala: true,
    banco: "selo-sala-ro",
    ufEmail: "ro",
    uf: "RO",
    svg: process.env.VUE_APP_SVG_RO,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "rr.sistemaselo",
    isSala: true,
    banco: "selo-sala-rr",
    ufEmail: "rr",
    uf: "RR",
    svg: process.env.VUE_APP_SVG_RR,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "se.sistemaselo",
    isSala: true,
    banco: "selo-sala-se",
    ufEmail: "se",
    uf: "SE",
    svg: process.env.VUE_APP_SVG_SE,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "to.sistemaselo",
    isSala: true,
    banco: "selo-sala-to",
    ufEmail: "to",
    uf: "TO",
    svg: process.env.VUE_APP_SVG_TO,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "df.sistemaselo",
    isSala: true,
    banco: "selo-sala-df",
    ufEmail: "df",
    uf: "DF",
    svg: process.env.VUE_APP_SVG_DF,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "rj.sistemaselo",
    isSala: true,
    banco: "selo-sala-rj",
    ufEmail: "rj",
    uf: "RJ",
    svg: process.env.VUE_APP_SVG_RJ,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "sp.sistemaselo",
    isSala: true,
    banco: "selo-sala-sp",
    ufEmail: "sp",
    logo: "logo.png",
    uf: "SP",
    svg: process.env.VUE_APP_SVG_SP,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "ms.sistemaselo",
    isSala: true,
    banco: "selo-sala-ms",
    ufEmail: "ms",
    uf: "MS",
    svg: process.env.VUE_APP_SVG_MS,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "al.sistemaselo",
    isSala: true,
    banco: "selo-sala-al",
    ufEmail: "al",
    uf: "AL",
    svg: process.env.VUE_APP_SVG_AL,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "ma.sistemaselo",
    isSala: true,
    banco: "selo-sala-ma",
    ufEmail: "ma",
    uf: "MA",
    svg: process.env.VUE_APP_SVG_MA,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  {
    tag: "sc.sistemaselo",
    isSala: true,
    banco: "selo-sala-sc",
    ufEmail: "sc",
    uf: "SC",
    svg: process.env.VUE_APP_SVG_SC,
    env: process.env.VUE_APP_BASE_API_SELO_UF,
  },
  //#endregion Selo

  {
    tag: "painel-na-hlg.desenvolvimentoterritorial",
    banco: "ce-na-hlg",
    ufEmail: "na-hlg",
    env: process.env.VUE_APP_BASE_API_NA_HLG,
    na: true,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
  },
  {
    tag: "painel-na-hlg.territoriosempreendedores",
    banco: "ce-na-hlg",
    ufEmail: "na-hlg",
    env: process.env.VUE_APP_BASE_API_NA_HLG,
    na: true,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
  },
  {
    tag: "painel.desenvolvimentoterritorial",
    ufEmail: "na",
    banco: "ce-na-hlg",
    env: process.env.VUE_APP_BASE_API_NA,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
    na: true,
  },
  {
    tag: "painel.territoriosempreendedores",
    ufEmail: "na",
    banco: "ce-na-hlg",
    env: process.env.VUE_APP_BASE_API_NA,
    uf: "NA",
    svg: process.env.VUE_APP_SVG_NA,
    na: true,
  },
  // #regiao LOCALHOST
  {
    tag: "localhost",
    banco: "ce-na-hlg",
    uf: "NA",
    env: process.env.VUE_APP_BASE_API_LOCAL,
    isTerritorio: true,
    isDT: true,
  },
  // #endregiao LOCALHOST
];

const ufsDefinitions = ufsPorDefinitions.map((item) => {
  return { ...ufsDefaultDefinitions, ...item };
});

let atual;
let hrf = window.location.href;

ufsDefinitions.forEach((item) => {
  if (hrf.includes("sistemaselo")) {
    if (hrf.includes("parceiros")) {
      item.isParceiro = true;
    }
    item.isSala = true;
  } else if (hrf.includes("selo")) {
    if (hrf.includes("parceiros")) {
      item.isParceiro = true;
    }
    item.isSala = true;
  } else if (hrf.includes("agentesterritoriais")) {
    item.isAT = true;
  } else if (hrf.includes("governancaempreendedora")) {
    item.isGov = true;
  } else if (hrf.includes("desenvolvimentoterritorial")) {
    item.isDT = true;
    item.isTerritorio = true;
  } else if (hrf.includes("territoriosempreendedores")) {
    item.isTerritorio = true;
  } else {
    item.isTerritorio = true;
    item.isCE = true;
  }

  if (item.isSala || item.isParceiro) {
    if (!item.title) item.title = titulos.sala;
    if (!item.corPrimaria) item.corPrimaria = "#DAA520";
    if (!item.logos) item.logos = logoSelo;
  } else if (item.isAT) {
    if (!item.title) item.title = titulos.at;
    if (!item.logos) item.logos = logoAT;

    if (!item.corPrimaria) item.corPrimaria = "#93CE98";
    if (!item.logo) item.logo = "logo_at_branca.png";
  } else if (item.isGov) {
    if (!item.title) item.title = titulos.gov;
    if (!item.logos) item.logos = logoGOV;
    if (!item.logo) item.logo = "Logo_Governança_Empreendedora-branca.png";
    if (!item.corPrimaria) item.corPrimaria = "#9079A8";
  } else if (item.isDT) {
    if (!item.title) item.title = titulos.dt;
    if (!item.logos) {
      item.logos = logoDT;
    }
    if (!item.corPrimaria) item.corPrimaria = "#000";
  } else if (item.isTerritorio && !item.isDT && !item.isCE) {
    if (!item.title) item.title = titulos.territorio;
    if (!item.logos) {
      item.logos = logoTerritorio;
    }
    if (!item.corPrimaria) item.corPrimaria = "#000";
  } else {
    if (!item.logo) item.logo = "logo_nacional.png";
    if (!item.logos) item.logos = logoCE;
    if (!item.title) item.title = titulos.ce;
    if (!item.corPrimaria) item.corPrimaria = "#000";
  }

  if (hrf.includes(item.tag)) {
    if (!item.env && item.tag.indexOf("na-hlg") !== -1) {
      item.env = process.env.VUE_APP_BASE_API_NA_HLG;
    } else if (!item.env && item.tag.indexOf("uf-hlg") !== -1) {
      item.env = process.env.VUE_APP_BASE_API_UF_HLG;
    } else if (!item.env && item.tag.indexOf("na") === -1) {
      item.env = process.env.VUE_APP_BASE_API_UF;
    } else if (!item.env && item.tag.indexOf("na") !== -1) {
      item.env = process.env.VUE_APP_BASE_API_NA;
    }

    if (item.isSala) {
      item.corPrimaria = "#DAA520";
    }

    atual = { ...item, urlTag: item.tag };
  }
});
export const atualUfDefinition = atual;
console.log("🚀 ~ atualUfDefinition:", atualUfDefinition);

/**
 *
 * @param {*} str - Essa string já vem automatica no filtro, não precisa passar
 * @param {*} tipo - cpf | telefone | email | nome
 * @returns
 */
export const esconderPalavras = (str, tipo) => {
  if (str) {
    if (tipo === "cpf") {
      return str.substring(0, 4) + "**" + str.substring(11, 14);
    } else if (tipo === "telefone") {
      return str.substring(0, 4) + "**" + str.substring(8, 11);
    } else if (tipo === "email") {
      let indexOfArroba = str.indexOf("@", 0);
      let indexOfArrobaDivided = parseInt(str.indexOf("@", 0) / 2);
      return (
        str.substring(0, indexOfArrobaDivided) +
        "**" +
        str.substring(indexOfArroba)
      );
    } else if (tipo === "nome") {
      let names = str.split(" ");
      return names[0] + " " + names[names.length - 1];
    }
  }
  return str;
};

export function mobileAndTabletCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
